import React from 'react';
import { MENU_ICON_ID } from '@/constants';

export interface IMenuIconProps {
  showMenu: boolean;
  className?: string;
  onClick: () => void;
}
const MenuIcon = ({ showMenu, className = '', onClick }: IMenuIconProps) => {
  return (
    <div id={MENU_ICON_ID} className={className}>
      <button
        id={MENU_ICON_ID}
        className="group flex h-12 w-12 flex-col items-center justify-center"
        onClick={onClick}
      >
        <div
          id={MENU_ICON_ID}
          className={`ease my-1 h-0.5 w-8 bg-gray-600 transition duration-300 ${
            showMenu
              ? 'translate-y-3 rotate-45 opacity-50 group-hover:opacity-100'
              : 'opacity-50 group-hover:opacity-100'
          }`}
        />
        <div
          id={MENU_ICON_ID}
          className={`ease my-1 h-0.5 w-8 bg-gray-600 transition duration-300 ${
            showMenu ? 'opacity-0' : 'opacity-50 group-hover:opacity-100'
          }`}
        />
        <div
          id={MENU_ICON_ID}
          className={`ease my-1 h-0.5 w-8 bg-gray-600 transition duration-300 ${
            showMenu
              ? '-translate-y-2 -rotate-45 opacity-50 group-hover:opacity-100'
              : 'opacity-50 group-hover:opacity-100'
          }`}
        />
      </button>
    </div>
  );
};

export default MenuIcon;
